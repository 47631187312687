<template>
  <a class="icon-view" @click="addToCart">
    <i class="fa fa-cart-plus"></i>
  </a>
</template>

<script>
export default {
  name: "AddToCart",
  props: {
    name: String,
    price: Number,
    image: String,
    pId: String,
    description: String,
  },
  data() {
    return {
      item: {
        productName: this.name,
        productPrice: this.price,
        productId: this.pId,
        productQuantity: 1,
        productImage: this.image,
        productDescription: this.description,
      },

    };
  },
  methods: {
    addToCart() {
      // eslint-disable-next-line no-undef
      $(".shopping-cart").fadeToggle("fast");

      // $("#miniCart").modal("show");
      this.$store.commit("addToCart", this.item);
    },
  },

};
</script>

<style></style>
