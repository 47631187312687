<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="miniCart"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">My Bag</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ul>
            <li
              v-for="item in this.$store.state.cart"
              :key="item.productName"
              class="media"
            >
              <img
                :src="item.productImage"
                width="80px"
                class="align-self-center mr-3"
              />
              <div class="media-body">
                <h5 class="mt-0">{{ item.productName }}</h5>
                <span
                  class="float-right"
                  @click="this.$store.commit('removeFromCart', item)"
                  >X</span
                >

                <p class="mt-0">{{ currency(item.productPrice) }}</p>

                <p class="mt-0">Quantity: {{ item.productQuantity }}</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Continue Shopping
          </button>
          <button type="button" class="btn btn-primary" @click="checkout">
            Checkout
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from "@/includes/helpers";

export default {
  name: "MiniCart",
  props: {
    name: String,
    price: Number,
    productId: String,
    image: String,
  },
  data() {
    return {
      item: {
        productName: this.name,
        productPrice: this.price,
        product_id: this.productId,
        productImage: this.image,
      },
    };
  },
  methods: {
    currency(value) {
      return helpers.withDecimalAndCurrency(value);
    },
    addToCart() {
      // eslint-disable-next-line no-undef
      $("#miniCart").modal("show");
      this.$store.commit("addToCart", this.item);
    },
    checkout() {
      // eslint-disable-next-line no-undef
      $("#miniCart").modal("hide");
      this.$router.push("/checkout");
    },
  },
};
</script>

<style></style>
