/* eslint-disable no-unused-vars */ /* eslint-disable no-restricted-globals */ /* eslint-disable
no-alert */
<template>
  <!-- Breadcroumbs start -->
  <Breadcrumbs title="Our Products" />
  <!-- Breadcroumbs end -->
  <!-- Product start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="row flex-lg-row-reverse">
        <!-- Modal -->

        <div class="col-12 col-lg-9">
          <div class="category-top justify-content-end">
            <div class="product-search mr-auto">
              <input
                type="search"
                name="product-search"
                class="form-control"
                placeholder="Search"
              />
            </div>
            <button @click="addNew" class="btn btn-primary">Add product</button>
            <div class="sort-by">
              <span>Sort By:</span>
              <span>
                <select name="show-item" class="form-control">
                  <option value="Default" selected>Default</option>
                  <option value="Newest">Newest</option>
                  <option value="Popular">Popular</option>
                </select>
              </span>
            </div>
            <div class="show-item">
              <span>Show items:</span>
              <span>
                <select name="show-item" class="form-control">
                  <option value="9 items" selected>9 items</option>
                  <option value="20 items">20 items</option>
                  <option value="40 items">40 items</option>
                  <option value="60 items">60 items</option>
                </select>
              </span>
            </div>
          </div>
          <div class="product-content">
            <div class="row">
              <!-- Product Item start -->
              <div v-for="product in products" :key="product.id" class="col-12 col-sm-6 col-lg-4">
                <div class="single-product single-product-style2">
                  <img
                    style="height: 253px"
                    :src="product.data().images[0]"
                    alt="Beekeeping Hive"
                  />
                  <div class="product-status">
                    <h4>
                      {{ product.data().name }}
                    </h4>
                    <div class="prod-hover-price">
                      <b>Price: </b>{{ currency(product.data().price) }}
                    </div>
                    <div class="product-action">
                      <router-link
                        :to="{ name: 'product', params: { id: product.id } }"
                        title=""
                        class="icon-view"
                        ><i class="fa fa-eye"></i
                      ></router-link>
                      <a href="" title="" class="icon-view">
                        <i class="fa fa-heart-o"></i>
                      </a>

                      <AddToCart
                        :name="product.data().name"
                        :description="product.data().description"
                        :image="product.data().images[0]"
                        :price="product.data().price"
                        :pId="product.id"
                      />
                      <a title="" class="icon-view">
                        <i class="fa fa-edit" @click="editProduct(product)"></i
                      ></a>
                      <a href="" title="" class="icon-view">
                        <i class="fa fa-trash" @click.prevent="deleteProduct(product.id)"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Product Item end -->
            </div>

            <div class="page-navigation">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1"
                      ><i class="fa fa-angle-left" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li class="page-item pagin-active">
                    <a class="page-link" href="#">1</a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item">
                    <a class="page-link" href="#"
                      ><i class="fa fa-angle-right" aria-hidden="true"></i
                    ></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <MiniCart />
        </div>
        <div class="col-12 col-lg-3">
          <div class="left-block left-menu">
            <h3>Category</h3>
            <ul>
              <li class="active">
                <a href="" title="Beehive wood">Beehive wood</a>
              </li>
              <li><a href="" title="Beehive styropor">Beehive styropor</a></li>
              <li><a href="" title="Hive parts">Hive parts</a></li>
              <li><a href="" title="Nucleus hive">Nucleus hive</a></li>
              <li><a href="" title="Queen excluder">Queen excluder</a></li>
              <li><a href="" title="Frames">Frames</a></li>
              <li><a href="" title="Honey Processing">Honey Processing</a></li>
              <li><a href="" title="Honey extractors">Honey extractors</a></li>
              <li><a href="" title="Clothing">Clothing</a></li>
              <li><a href="" title="Queen Rearing">Queen Rearing</a></li>
              <li><a href="" title="Hive hardware">Hive hardware</a></li>
              <li><a href="" title="Beewax">Beewax</a></li>
              <li><a href="" title="Packaging">Packaging</a></li>
              <li><a href="" title="Feed for bees">Feed for bees</a></li>
            </ul>
          </div>
          <div class="left-block Special-offer">
            <img src="../assets/images/special-offer.jpg" alt="" />
            <div class="sell-off">
              10%<br />
              Off Now
            </div>
          </div>
          <div class="left-block wow fadeInUp">
            <a href="#" class="bee-button">Order Your Product</a>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal"
      id="product"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="modal == 'new'" class="modal-title" id="editLabel">
              Add Product
            </h5>
            <h5 v-if="modal == 'edit'" class="modal-title" id="editLabel">
              Edit Product
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- main product -->
              <div class="col-md-8">
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Product Name"
                    v-model="product.name"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <textarea
                    name="description"
                    class="form-control"
                    placeholder="Product description"
                    v-model="product.description"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>
              <!-- product sidebar -->
              <div class="col-md-4">
                <h4 class="display-6">Product Details</h4>
                <hr />

                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Product price"
                    v-model.number="product.price"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <input
                    type="text"
                    v-on:keyup.enter="addTag"
                    placeholder="Product tags"
                    v-model="tag"
                    class="form-control"
                  />
                  <div class="d-flex">
                    <p v-for="(tag, index) in product.tags" :key="index">
                      <span class="p-1 tag-wrap" @click="deleteTag">{{ tag }}</span>
                    </p>
                  </div>
                </div>

                <div class="form-group">
                  <label for="product_image">Product Images</label>
                  <input type="file" @change="uploadImage" class="form-control" />
                </div>
                <div class="form-group d-flex">
                  <div class="p-1" v-for="(image, index) in product.images" :key="image.id">
                    <div class="img-wrap">
                      <img style="width: 80px" :src="image" alt="" />
                      <span class="delete-img" @click="deleteImage(image, index)">X</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button
              v-if="modal == 'new'"
              @click="addProduct()"
              type="button"
              class="btn btn-primary"
            >
              Save changes</button
            ><button
              v-if="modal == 'edit'"
              @click="updateProduct()"
              type="button"
              class="btn btn-primary"
            >
              Apply changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Product End -->
  <Newsletter />
</template>

<script>
// @ is an alias to /src
// import { VueEditor } from 'vue2-editor';
import { db, storage } from "@/includes/firebase";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import AddToCart from "@/components/AddToCart.vue";
import Newsletter from "@/components/Newsletter.vue";
import MiniCart from "@/components/MiniCart.vue";

import helpers from "@/includes/helpers";

export default {
  name: "Shop",
  data() {
    return {
      products: [],
      product: {
        name: null,
        description: null,
        price: 0,
        tags: [],
        images: [],
      },
      modal: null,
      tag: null,
      activeItem: null,
    };
  },
  components: {
    Breadcrumbs,
    Newsletter,
    AddToCart,
    MiniCart,
  },

  methods: {
    addTag() {
      this.product.tags.push(this.tag);

      this.tag = "";
    },
    addNew() {
      this.reset();
      this.modal = "new";
      // eslint-disable-next-line no-undef
      $("#product").modal("show");
    },
    reset() {
      this.product = {
        name: null,
        description: null,
        price: null,
        tags: [],
        images: [],
      };
    },
    // Remove tag from product
    deleteTag(tag, index) {
      this.product.tags.splice(index, 1);
    },
    deleteImage(img, index) {
      const image = storage.refFromURL(img);
      this.product.images.splice(index, 1);
      image
        .delete()
        .then(() => {
          console.log("image deleted");
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log(error, "an error occurred");
        });
    },
    addProduct() {
      db.collection("products")
        .add(this.product)
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          this.readData();
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    readData() {
      this.products = [];
      db.collection("products")
        .get()
        .then((querySnapshot) => {
          // this.products = querySnapshot;
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            this.products.push(doc);
          });
        });
      // eslint-disable-next-line no-undef
      $("#product").modal("hide");
    },
    // eslint-disable-next-line no-unused-vars
    deleteProduct(doc) {
      // eslint-disable-next-line no-alert
      if (window.confirm("Are you sure?")) {
        db.collection("products")
          .doc(doc)
          .delete()
          .then(() => {
            this.readData();
            console.log("Document successfully deleted!");
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      }
    },
    editProduct(product) {
      this.modal = "edit";
      this.product = product.data();

      // eslint-disable-next-line no-undef
      $("#product").modal("show");

      this.activeItem = product.id;
    },
    updateProduct() {
      db.collection("products")
        .doc(this.activeItem)
        .update(this.product)
        .then(() => {
          // eslint-disable-next-line no-undef
          $("#edit").modal("hide");
          this.readData();
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    uploadImage(e) {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const storageRef = storage.ref(`products/${file.name}`);
        const uploadTask = storageRef.put(file);

        // eslint-disable-next-line no-unused-vars
        uploadTask.on(
          "state_changed",
          // eslint-disable-next-line no-unused-vars
          (snapshot) => {
            // eslint-disable-next-line no-unused-vars
          },
          // eslint-disable-next-line no-unused-vars
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.product.images.push(downloadURL);
              console.log("File available at", downloadURL);
            });
          },
        );
      }
    },
    currency(value) {
      return helpers.withDecimalAndCurrency(value);
    },
  },
  // For updating on changes on real time
  watcher() {
    db.collection("products").onSnapshot((querySnapshot) => {
      this.products = [];
      querySnapshot.forEach((doc) => {
        this.products.push(doc);
      });
    });
  },

  created() {
    this.readData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.img-wrapp {
  position: relative;
}
.img-wrapp span.delete-img {
  position: absolute;
  top: -14px;
  left: -2px;
}
.img-wrapp span.delete-img:hover {
  cursor: pointer;
}
.tag-wrapp {
  position: relative;
}
.tag-wrap:hover {
  cursor: pointer;
}
</style>
